





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ComponentType } from '@/models'
import * as ComponentTypes from '@/components/componentTypes'

@Component({})
export default class AdminComponentsIndexPlaceholder extends Vue {
  @Prop({ type: String, required: true }) componentTypeName!: string

  /** Current component type */
  get componentType(): ComponentType<any> {
    let ret = (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
    return ret
  }
}
